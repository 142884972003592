/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState } from "react";
import { useGLTF, useTexture, meshBounds } from "@react-three/drei"

export default function Travaux({position, rotation, scale, lightOn, setLightOn}) {
  const { nodes, materials } = useGLTF("./travaux.glb")

  const bakedTextureOn = useTexture('./baked_lighton.jpg')
  bakedTextureOn.flipY = false

  const bakedTextureOff = useTexture('./baked_lightoff.jpg')
  bakedTextureOff.flipY = false

  const eventHandler = () =>
    {
        setLightOn(!lightOn)
    }

  return (
    <group position={position} rotation={rotation} scale={scale} dispose={null}>
      <mesh
        name="echelle"
        castShadow
        receiveShadow
        geometry={nodes.echelle.geometry}
        position={[0.211, 0.2, -1.294]}
        rotation={[0, -0.591, 0]}>
        <meshBasicMaterial map={ lightOn ? bakedTextureOn : bakedTextureOff } />
    </mesh>
      <mesh
        name="pot"
        castShadow
        receiveShadow
        geometry={nodes.pot.geometry}
        position={[-0.328, 0.2, 0.469]}>
        <meshBasicMaterial map={ lightOn ? bakedTextureOn : bakedTextureOff } />
      </mesh>
      <mesh
        name="pinceau"
        castShadow
        receiveShadow
        geometry={nodes.pinceau.geometry}
        position={[0.125, 1.111, 0.187]}
        rotation={[0, 0.167, 0]}>
            <meshBasicMaterial map={ lightOn ? bakedTextureOn : bakedTextureOff } />
        </mesh>
      <mesh
        name="douille"
        castShadow
        receiveShadow
        geometry={nodes.douille.geometry}
        position={[0, 4.503, 0]}>
        <meshBasicMaterial map={ lightOn ? bakedTextureOn : bakedTextureOff } />
    </mesh>
      <mesh
        name="murs"
        castShadow
        receiveShadow
        geometry={nodes.murs.geometry}>
        <meshBasicMaterial map={ lightOn ? bakedTextureOn : bakedTextureOff } />
      </mesh>
      <mesh
        name="sol"
        castShadow
        receiveShadow
        geometry={nodes.sol.geometry}>
        <meshBasicMaterial map={ lightOn ? bakedTextureOn : bakedTextureOff } />
      </mesh>
      <mesh
        name="ampoule"
        castShadow
        raycast={ meshBounds }
        geometry={nodes.ampoule.geometry}
        position={[0, 4.503, 0]}
        onClick={eventHandler}>
        <meshBasicMaterial color={ lightOn ? "#fff" : "#aaa" } />
      </mesh>
      <mesh
        name="logo"
        castShadow
        geometry={nodes.logo.geometry}
        material={nodes.logo.material}
        position={[0.088, 3.693, -3.762]}
      >
        <meshBasicMaterial color="#ffffff" />
      </mesh>
    </group>
  );
}

useGLTF.preload("/travaux.glb");
