import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'
import { useState } from 'react'
import { useEffect } from 'react'

const root = ReactDOM.createRoot(document.querySelector('#root'))

function IndexComponent(){
    const [lightOn, setLightOn] = useState(true)

    useEffect(() => {
        if(lightOn) {
            document.body.classList="jour"
        } else {
            document.body.classList="nuit"
        }
    })

    return (
        <>
            <div>
            <strong className="title"><span className="dokat">Dokat</span> se refait une beauté</strong>
            <br/>
            <small className="subtitle">Mais nous revenons bientôt !</small>
            </div>
            <p>
            Vous pouvez toujours nous contacter en cliquant <a href="mailto:contact@dokat.fr">ici</a>
            </p>
            <Canvas
                flat
                camera={ {
                    fov: 45,
                    near: 0.1,
                    far: 200,
                    position: [ 0, 2, 6 ]
                } }
            >
                <Experience lightOn={lightOn} setLightOn={setLightOn} />
            </Canvas>
            <footer>
            Défi : essayez d'éteindre la lumière avant de partir !
            <br/>
            © 2024 Dokat, agence multimédia - Paris
            </footer>
        </>
    )
}

root.render(
    <IndexComponent/>
)
