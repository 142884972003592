import { ContactShadows, Environment, Center, PresentationControls, OrbitControls, Float } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import { Perf } from 'r3f-perf'
import { Suspense, useState } from 'react'

import Model from './Model'
import Placeholder from './Placeholder'
import Travaux from './Travaux'


export default function Experience({lightOn, setLightOn})
{
    const { viewport } = useThree()

    return <>

        {/* <Perf position="top-left" /> */}

        <OrbitControls
            enablePan = {false}
            minPolarAngle={1}
            maxPolarAngle={1.5}
            minAzimuthAngle={-.785}
            maxAzimuthAngle={.785}
            maxDistance={15}
            minDistance={3}
        />

        <color args={ lightOn ? [ '#fff' ] : ['#000'] } attach="background" />

        <directionalLight castShadow position={ [ 1, 2, 3 ] } intensity={ 1.5 } />
        <ambientLight intensity={ 0.5 } />

        <Float>
            <Suspense fallback={ <Placeholder position-y={ 1 } scale={ [ 3, 2, 3 ] }/>}>
                <Travaux position={[0,0,0]} rotation={[0,-.785,0]} scale={ [ (viewport.width / 15), (viewport.width / 15), (viewport.width / 15) ] } lightOn={lightOn} setLightOn={setLightOn}/>
            </Suspense>
        </Float>
    </>
}